import React, { useState, useEffect } from 'react';
import Button from 'common/components/Button';
import styled from 'styled-components';
import { Link } from 'gatsby';

const BookDemoButtonStyle = styled.div`
  .book-demo-button {
    background-color: #f58634ff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: none;

    @media (max-width: 768px) {
      display: inline-block;
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
    }
  }
`;

const BookDemoButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      if (window.innerWidth <= 768) {
        if (scrollTop + windowHeight >= documentHeight) {
          // User has reached the bottom of the page
          setIsVisible(false);
        } else if (scrollTop > 100) {
          // User has scrolled down, show the button
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <BookDemoButtonStyle>
      {isVisible && (
        <Link to="/request-demo">
          <Button title="Book a Demo" className="book-demo-button" />
        </Link>
      )}
    </BookDemoButtonStyle>
  );
};

export default BookDemoButton;
